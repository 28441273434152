import { backOffice } from '@emobg/web-api-client';
import { INITIAL_STATUS, createCustomStore, withScopes } from '@emobg/web-utils';
import { clearErrors, toastError } from '@/utils';

const {
  getEditBooking: getBooking,
  getBookingFraudValues,
  getBookingFraud,
  postBookingFraud,
  putBookingFraud,
  deleteBookingFraud,
} = backOffice.bookings;

export const BOOKING_DETAIL_SCOPES = {
  fraudCard: 'fraudCard',
  fraudValues: 'fraudValues',
  fraudForm: 'fraudForm',
  fraudDelete: 'fraudDelete',
};

const bookingDetailsModule = createCustomStore(({ runAsyncFlow }) => withScopes(BOOKING_DETAIL_SCOPES, ({
  state: {
    status: { ...INITIAL_STATUS },
    data: null,
    error: null,
    fraudCard: {
      data: null,
    },
    fraudValues: {
      data: null,
    },
  },
  mutations: {
    clearErrors,
    setError(state, payload) {
      toastError.bind(this)(
        state,
        payload,
        [null, BOOKING_DETAIL_SCOPES.fraudDelete],
      );
    },
  },
  actions: {
    async getBooking({ commit }, bookingUuid) {
      await runAsyncFlow(commit, {
        request: getBooking,
        params: [bookingUuid, 'v2'],
      });
    },
    async getBookingFraud({ commit }, bookingUuid) {
      await runAsyncFlow(commit, {
        request: getBookingFraud,
        params: [bookingUuid],
        scope: BOOKING_DETAIL_SCOPES.fraudCard,
      });
    },
    async getBookingFraudValues({ commit }) {
      await runAsyncFlow(commit, {
        request: getBookingFraudValues,
        params: [],
        scope: BOOKING_DETAIL_SCOPES.fraudValues,
      });
    },
    async postBookingFraud({ commit }, { data, bookingUuid }) {
      await runAsyncFlow(commit, {
        request: postBookingFraud,
        params: [data, bookingUuid],
        scope: BOOKING_DETAIL_SCOPES.fraudForm,
      });
    },
    async putBookingFraud({ commit }, { data, bookingUuid }) {
      await runAsyncFlow(commit, {
        request: putBookingFraud,
        params: [data, bookingUuid],
        scope: BOOKING_DETAIL_SCOPES.fraudForm,
      });
    },
    async deleteBookingFraud({ commit }, bookingUuid) {
      await runAsyncFlow(commit, {
        request: deleteBookingFraud,
        params: [bookingUuid],
        scope: BOOKING_DETAIL_SCOPES.fraudDelete,
      });
    },
  },
})));
export default bookingDetailsModule;
