import { backOffice } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { toastError } from '@/utils';

const { postBookingUsage } = backOffice.bookings;

export const BOOKING_MANAGEMENT_SCOPES = {
  usageStatusForm: 'usageStatusForm',
};

const bookingManagementModule = createCustomStore(({ runAsyncFlow }) => withScopes(BOOKING_MANAGEMENT_SCOPES, ({
  mutations: {
    setError(state, payload) {
      toastError.bind(this)(
        state,
        payload,
        [BOOKING_MANAGEMENT_SCOPES.usageStatusForm],
      );
    },
  },
  actions: {
    async postBookingUsage({ commit }, { bookingUuid, data }) {
      await runAsyncFlow(commit, {
        request: postBookingUsage,
        params: [bookingUuid, data],
        scope: BOOKING_MANAGEMENT_SCOPES.usageStatusForm,
      });
    },
  },
})));
export default bookingManagementModule;
