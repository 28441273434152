import toNumber from 'lodash/toNumber';

export const parseCost = ({
  costPerUnit,
  comment,
  type,
  units,
  credit,
  taxPercentage,
} = {}) => ({
  costPerUnit: toNumber(costPerUnit || 0),
  taxPercentage: toNumber(taxPercentage || 0),
  units: toNumber(units || 0),
  credit,
  comment,
  type,
});
